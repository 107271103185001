const getScreenQuadrant = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const w = window.innerWidth;
    const h = window.innerHeight;
    return {
        x: centerX < w / 2 ? "left" : "right",
        y: centerY < h / 2 ? "top" : "bottom",
    };
};

const registerUnfocus = () => {
    const allExpandableMenus = document.querySelectorAll("li[data-expands-x]");
    allExpandableMenus.forEach((menu) => {
        menu.addEventListener("click", (e) => {
            e.stopPropagation();
            menu.classList.toggle('focus-within');
            const allChildMenus = menu.querySelectorAll("li[data-expands-x]");
            allChildMenus.forEach((childMenu) => {
                childMenu.classList.remove('focus-within');
            });
        });
    });

    document.addEventListener("click", (e) => {
        const isMenuClicked = (e.target as HTMLElement).closest("li[data-expands-x]");
        if (isMenuClicked) {
            return;
        }

        const allFocused = document.querySelectorAll('.focus-within');
        allFocused.forEach((focused) => {
            focused.classList.remove('focus-within');
        });
    });
}

const registerIndicatorClicks = () => {
    const allIndicators = document.querySelectorAll("[data-menu-indicator]");
    allIndicators.forEach((indicator) => {
        let lastClickTime = 0;
        indicator.addEventListener("click", (e) => {
            e.stopPropagation();
            e.preventDefault(); // prevent parent menu click

            // guard against double clicks
            if (Date.now() - lastClickTime >= 100) {
                const menu = indicator.closest("li[data-expands-x]");
                if (menu) {
                    menu.classList.toggle('focus-within');
                }
            }
            lastClickTime = Date.now();
        });
    });
}

const updateMenus = () => {
    const allMenus = document.querySelectorAll("ul[data-menu] > li");
    allMenus.forEach((menu) => {
        // if it doesn't have the data-events attribute, add it and register the event listener
        if (!menu.hasAttribute("data-events")) {
            menu.setAttribute("data-events", "true");
            menu.addEventListener("mouseenter", (e) => {
                const subMenus = menu.querySelectorAll("ul > li");
                subMenus.forEach((subMenu) => {
                    const subMenuQuadrant = getScreenQuadrant(subMenu as HTMLElement);
                    switch (subMenuQuadrant.x) {
                        case "left":
                            subMenu.setAttribute("data-expands-x", "right");
                            break;
                        case "right":
                            subMenu.setAttribute("data-expands-x", "left");
                            break;
                    }
                    switch (subMenuQuadrant.y) {
                        case "top":
                            subMenu.setAttribute("data-expands-y", "bottom");
                            break;
                        case "bottom":
                            subMenu.setAttribute("data-expands-y", "top");
                            break;
                    }
                });
            });
        }
        const parentScreenQuadrant = getScreenQuadrant(menu as HTMLElement);
        switch (parentScreenQuadrant.y) {
            case "top":
                menu.setAttribute("data-expands-y", "bottom");
                break;
            case "bottom":
                menu.setAttribute("data-expands-y", "top");
                break;
        }
        switch (parentScreenQuadrant.x) {
            case "left":
                menu.setAttribute("data-expands-x", "right");
                break;
            case "right":
                menu.setAttribute("data-expands-x", "left");
                break;
        }
    });
};

const registerStubClicks = () => {
    document.addEventListener("click", (e) => {
        const target = e.target as HTMLElement;
        if (
            target.tagName === "A" &&
            target.getAttribute("href") === "#menu-stub"
        ) {
            e.preventDefault();
        }
        const closestLink = target.closest("a");
        if (closestLink && closestLink.getAttribute("href") === "#menu-stub") {
            e.preventDefault();
        }
    });
};

const register = () => {
    setTimeout(updateMenus, 200);
    setTimeout(registerUnfocus, 200);
    registerStubClicks();
    registerIndicatorClicks();
    window.addEventListener("resize", updateMenus);
};

export {register};

import { throttle } from "../lib/Utility";

const $body = document.querySelector("body");
let last_offset = 0;

interface DeltaScrollInteractions {
  // the number of pixels the page must be scrolled before the delta is considered "down"
  dead_zone?: number;
  // the number of pixel difference required before the delta direction is decided
  minimum_movement?: number;
  // the number of pixels the page must be scrolled before the below-threshold attribute is set to true
  below_threshold?: number;
  // the width at which the screen is considered small
  small_width?: number;
  // the height at which the screen is considered small
  small_height?: number;
}

/**
 * applies data attributes to the body to make targeting via css possible:
 * data-mouse-delta ("up" or "down")
 * data-below-fold (bool)
 * data-below-threshold (bool)
 * data-small-width (bool)
 * data-small-height (bool)
 */
const register = ({
  dead_zone = 0,
  minimum_movement = 0,
  below_threshold = 200,
  small_width = 768,
  small_height = 915
}: DeltaScrollInteractions): void => {
    const events = ["scroll", "resize", "DOMContentLoaded"];
    events.forEach((event) => {
        window.addEventListener(
            event,
            throttle(() => {
                // don't process if the body has data-saved-scroll-top
                if ($body.hasAttribute("data-saved-scroll-top")) {
                    return;
                }

                const offset =
                    document.documentElement.scrollTop;

                // decide mouse delta
                if (offset > last_offset + minimum_movement && offset > dead_zone) {
                    $body.dataset.mouseDelta = "down";
                } else if (offset < last_offset - minimum_movement || offset === 0) {
                    $body.dataset.mouseDelta = "up";
                }

                // decide if we're below the fold
                const client_height = document.documentElement.clientHeight;
                $body.dataset.belowFold = offset >= client_height ? "true" : "false";

                // decide if below threshold
                $body.dataset.belowThreshold = offset >= below_threshold ? "true" : "false";

                // decide if on a small screen
                $body.dataset.smallWidth = window.innerWidth <= small_width ? "true" : "false";
                $body.dataset.smallHeight = window.innerHeight <= small_height ? "true" : "false";

                // set our stored offset
                if (
                    offset === 0 ||
                    offset > last_offset + minimum_movement ||
                    offset < last_offset - minimum_movement
                ) {
                    last_offset = offset;
                }
            })
        );
    });
};

export { register };
